// Fonts
@import url('https://fonts.cdnfonts.com/css/source-sans-pro');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';
@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';
//@import '@fancyapps/ui/dist/fancybox.css';
//@import '@fancyapps/ui/dist/carousel.css';

@import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

@import 'general';

//Sections
@import 'sections/header';
@import 'sections/navbar-categories';
@import 'sections/slider-box';
@import 'sections/categories';
@import 'sections/products';
@import 'sections/product-view';
@import 'sections/pages';
@import 'sections/status-view';
@import 'sections/footer';
