.navbar-categories {
    margin-top: 20px;

    nav {
        padding: 10px 20px;
        border-radius: 14px;
        background: #262638;


        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-left: 5px;
                margin-right: 5px;
                position: relative;

                &:before {

                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 50%;
                    top: 30%;
                    right: 0;
                    border-right: 1px solid rgba(65, 65, 65, 1);
                }

                &:last-child {
                    &:before {
                        border-right: none;
                    }
                }

                a {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-decoration: none;
                    padding-left: 5px;
                    padding-right: 15px;
                    transition: color .4s ease;


                    &:hover, &.active {
                        color: #F09819;
                    }


                }

            }
        }
    }

}