header {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 9999999;
    //margin-top: 60px;

    background: rgba(22,22,33, .95);

    nav {
        padding-top: 34px;
        padding-bottom: 34px;

        display: flex;
        align-items: center;

        .navbar-brand {
            display: block;
        }

        ul {
            list-style: none;
            margin: 0;

            &.primary-menu {
                li {
                    padding-left: 20px;
                    padding-right: 20px;

                    a {
                        text-transform: uppercase;
                        transition: color .4s ease;

                        &:hover, &.active {
                            color: #FF9700;
                        }

                    }
                }
            }

            li {

                a {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    text-decoration: none;
                }

                .dropdown-menu {
                    border-radius: 5px;
                    background: #262638;

                    @media (max-width: 992px) {
                        position: absolute;
                    }


                }



                &.language-menu {
                    position: relative;

                    .dropdown-button {
                        text-transform: uppercase;
                        z-index: 9999;
                        padding-right: 15px;

                        @media (max-width: 1199px) {
                            padding-right: 10px;
                        }

                        svg {
                            margin-top: -3px;
                        }

                        &.active {
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            padding: 5px 0;
                            padding-left: 18.5px;
                            padding-right: 15px;
                            background: #262638;

                            @media (max-width: 1199px) {
                                padding-right: 10px;
                            }



                        }

                    }

                    .dropdown-language-menu {
                        position: absolute;
                        list-style: none;
                        padding: 5px 0;
                        padding-right: 15px;
                        margin-left: 0px;
                        margin-top: -8px;
                        display: none;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        background: #262638;
                        z-index: 999;

                        @media (max-width: 1199px) {
                            padding-right: 1px;
                        }

                        @media (max-width: 992px) {
                            padding-right: 10px;

                        }

                        @media (max-width: 330px) {
                            padding-right: 0px;

                        }


                        &.opened {
                            display: block;
                            //right: 0;

                            @media (max-width: 992px) {
                                margin-top: -5px;
                            }

                        }

                        ul {
                            padding: 0;

                            li {
                                @media (max-width: 992px) {
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                }

                                a {
                                    color: #FFF;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-decoration: none;
                                    display: block;
                                    padding: 10px 20px;

                                    @media (max-width: 992px) {
                                        text-align: center;
                                        padding-top: 2px;
                                        padding-bottom: 2px;
                                    }

                                    &:hover {
                                        color: #FF9700;
                                    }


                                }
                            }
                        }

                    }
                }

                &.secondary-menu {
                    position: relative;

                    .dropdown-secondary-menu {
                        position: absolute;
                        list-style: none;
                        padding: 5px 0;
                        margin-top: 10px;
                        display: none;
                        border-radius: 5px;
                        background: #262638;
                        z-index: 9999;



                        &.opened {
                            display: block;
                            right: 0;

                            @media (max-width: 992px) {
                                margin-top: 20px;
                            }

                        }

                        ul {
                            padding: 0;

                            li {
                                @media (max-width: 992px) {
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                }

                                &:hover {
                                    background-color: #303046;
                                }

                                a {
                                    color: #FFF;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-decoration: none;
                                    display: block;
                                    padding: 10px 40px;

                                    @media (max-width: 992px) {
                                        text-align: center;
                                    }

                                }
                            }
                        }

                    }
                }



            }


        }
    }
}


