.products {
    margin-top: 64px;

    h1 {
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 32px;
        text-transform: uppercase;
    }

    .card {
        padding: 0;
        margin-bottom: 20px;
        border-radius: 23px;
        background: #1D1D2A;

        &:hover {
            .image-box {
                img {
                    transform: scale(1.2);

                }

            }
        }

        &-header {
            padding: 0;
            position: relative;

            &__status {
                position: absolute;
                right: 10px;
                bottom: 10px;
                padding: 5px 20px 5px;
                border-radius: 24px;

                &.undetected {
                    background-color: #28AD25;
                }

                &.recommend {
                    background-color: #2D9CDB;
                }

                &.use-at-own-risk {
                    background-color: #9400D3;
                }

                &.on-update {
                    background-color: #1E1E1E;
                }

                span {
                    color: #FFF;
                    text-align: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            .image-box {
                width: 100%;
                //height: 207px;
                min-height: 180px;
                overflow: hidden;
                border-top-left-radius: 23px;
                border-top-right-radius: 23px;

                @media (max-width: 992px) {
                    height: 157px;
                }

                img {
                    width: 100%;
                    height: auto;
                    border-top-left-radius: 23px;
                    border-top-right-radius: 23px;
                    transform: scale(1);
                    transition: all .4s ease;
                    //margin-top: -20px;

                }
            }

        }

        &-body {
            //padding: 0;

            &__category-title {
                text-align: left;
                min-height: 75px;

                h2 {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            &__price {
                text-align: right;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                span {
                    color: #F09819;

                }
            }

            .btn {
                width: 100%;
                border: none;

                &.btn-primary {
                    color: #161621;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    border-radius: 5px;
                    background: #F09819;
                    text-transform: uppercase;
                    transition: background-color .4s ease;

                    &:hover {
                        background: #E0720D;
                    }

                }
            }
        }

    }
}