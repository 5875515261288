.product-view {
    margin-top: 64px;
    position: relative;

    .bg-all {
        padding: 20px;
        border-radius: 23px;
        background: #1D1D2A;

    }
    
    .title-box {
        position: relative;
        margin-bottom: 24px;

        h1 {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .status {
            position: absolute;
            right: 10px;
            bottom: 10px;
            padding: 0 20px 5px;
            border-radius: 24px;

            &.undetected {
                background-color: #28AD25;
            }

            &.recommend {
                background-color: #2D9CDB;
            }

            &.use-at-own-risk {
                background-color: #9400D3;
            }

            &.on-update {
                background-color: #1E1E1E;
            }

            span {
                color: #FFF;
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                text-transform: uppercase;
            }
        }

    }

    .gallery-box {
        width: 100%;
        //display: flex;

        padding: 20px;
        border-radius: 23px;
        background: #1D1D2A;

        //@media (max-width: 992px) {
        //    display: block;
        //}



        .thumbs {
            width: 100%;
            //height: 334px;
            //display: block;
            //flex-direction: column;
            overflow: hidden;

            //@media (max-width: 992px) {
            //    width: 100%;
            //
            //}


            .thumbs-container {
                height: 100%;
                //display: flex;
                //flex-direction: column;
                overflow-y: scroll;
                overflow-x: hidden;
                scrollbar-color: transparent transparent;
                scrollbar-width: thin;

                a {
                    display: block;
                    margin-bottom: 20px;
                    text-align: center;
                    text-decoration: none;

                    padding-right: 7px;
                    border-right: 2px solid transparent;

                    &.active {
                        border-right: 2px solid #F09819;
                    }

                    img {
                        width: 232px;
                        height: auto;
                        max-height: 83px;
                        border-radius: 10px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: transparent;
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }

        }

        .gallery-main-image {
            //width: 531px;
            //height: 334px;
            width: 100%;
            height: auto;

            //flex-grow: 1;
            //padding-left: 20px;

            @media (min-width: 1600px) {
                //padding-left: 80px;

            }


            img {
                width: 100%;
                height: auto;
                max-height: 100%;
                transition: all 0.4s ease;
            }
        }
    }

    .description-box {
        margin-top: 64px;

        .tabs {
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                list-style: none;
                @media (max-width: 992px) {
                    margin: 0;
                    padding: 0;

                }

                li {
                    margin-right: 32px;

                    @media (max-width: 770px) {
                        width: 100%;
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 20px;
                    }

                    @media (max-width: 992px) {
                        width: 100%;
                        margin-right: 20px;
                        margin-left: 0;
                        margin-top: 20px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        width: 100%;
                        padding: 13px 50px;

                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-align: center;

                        border: 1px solid #fff;
                        border-radius: 24px;
                        background: transparent;
                        text-decoration: none;
                        transition: all .4s ease;

                        @media (max-width: 992px) {
                            display: block;
                        }

                        &:hover, &.active {
                            color: #161621;
                            border: 1px solid #F09819;
                            background: #F09819;
                        }

                    }
                }
            }

        }

        .tab-content {
            color: rgba(255, 255, 255, .7);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-top: 34px;
            padding: 20px;
            border-radius: 24px;
            background: #262638;

            p {
                margin-bottom: 10px;
            }

            ul {
                //margin: 0;

                li {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }
    }


    .order-box {
        padding: 20px;
        border-radius: 24px;
        background: #262638;

        .description {
            color: rgba(255, 255, 255, .7);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            p {
                margin-bottom: 10px;
            }

            ul {
                margin: 0;

                li {
                    margin-bottom: 10px;
                }
            }

            h5 {
                color: rgba(255, 255, 255, .7);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .period {
            margin-top: 30px;
            h4 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }

            .button-group {
                margin-top: 30px;

                .form-group {
                    margin-bottom: 20px;

                    input[type=radio] {
                        display: none;

                        &:checked + label {
                            color: #161621;
                            border: 1px solid #F09819;
                            background: #F09819;
                        }
                    }

                    label {
                        width: 100%;
                        padding: 7px 10px;

                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-align: center;

                        cursor: pointer;

                        border: 1px solid #fff;
                        border-radius: 24px;
                        background: transparent;
                        transition: all .4s ease;

                        &:hover {
                            color: #161621;
                            border: 1px solid #F09819;
                            background: #F09819;
                        }

                    }
                }
            }
        }

        .cost {
            margin-top: 30px;
            h4 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }

            .price {
                span {
                    color: #F09819;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                }
            }


        }

        .term {
            margin-top: 20px;

            .agreement {
                $switch-background: #414141;
                $active-background: #F09819;
                position: relative;
                margin-bottom: 10px;

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 32px;
                    height: 16px;
                    cursor: pointer;


                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }

                    .slider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $switch-background;
                        border-radius: 34px;
                        transition: 0.4s;

                        &:before {
                            position: absolute;
                            content: "";
                            height: 16px;
                            width: 16px;
                            left: 0px;
                            bottom: 0px;
                            background-color: #fff;
                            border-radius: 50%;
                            transition: 0.4s;
                        }
                    }

                    input:checked + .slider {
                        background-color: $active-background;

                        &:before {
                            transform: translateX(16px);
                            background-color: #fff;

                        }
                    }
                }

                .text {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    position: absolute;
                    left: 50px;

                    a {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

        }

        .btn {
            width: 100%;
            border: none;
            margin-top: 20px;

            &.btn-primary {
                color: #161621;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                border-radius: 5px;
                background: #F09819;
                text-transform: uppercase;
                transition: all .4s ease;

                &:hover {
                    background: #E0720D;
                }

            }
        }

    }




}