.status-view {
    margin-top: 44px;
    position: relative;


    .title-box {
        position: relative;
        margin-bottom: 34px;

        h1 {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
        }

    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        list-style: none;

        @media (max-width: 992px) {
            width: 100%;
            display: block;
        }


        li {
            margin: 0;
            margin-right: 30px;
            padding-bottom: 20px;
            position: relative;

            @media (max-width: 992px) {
                margin-right: auto;
                margin-top: 10px;
            }

            &.active {

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 40%);
                    left: 20%;
                    right: 20%;
                    border-bottom: 1px solid #F09819;

                    @media (max-width: 992px) {
                        width: calc(100% - 60%);
                        left: 30%;
                        right: 30%;
                        bottom: 5px;

                    }


                }
            }


            .status {
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                text-transform: uppercase;
                text-decoration: none;

                padding: 10px 40px 10px;
                border-radius: 24px;

                @media (max-width: 992px) {
                    width: 100%;
                    display: block;
                    padding: 10px;

                }

                @media (max-width: 1200px) {
                    padding: 10px 30px 10px;

                }


                &.undetected {
                    background-color: #28AD25;
                }

                &.recommend {
                    background-color: #2D9CDB;
                }

                &.use-at-own-risk {
                    background-color: #9400D3;
                }

                &.on-update {
                    background-color: #1E1E1E;
                }
            }
        }
    }

    .table-responsive {
        margin-top: 64px;
        padding: 20px;
        border-radius: 24px;
        background: #262638;

        table.table {
            --bs-table-bg: #262638 !important;

            th {
                color: #DADADA;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 25px;
                padding-right: 25px;
                border-right: 1px solid #414141;
                border-bottom: 1px solid #414141;

                &:last-child {
                    border-right: none;

                }

            }

            td {
                padding-left: 25px;
                padding-right: 25px;
                border-right: 1px solid #414141;
                border-bottom: 1px solid #414141;

                &:last-child {
                    border-right: none;

                }
            }
            .text-default {
                color: #DADADA;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;


                h2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                }

                h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                }

                a {
                    color: #DADADA;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;

                    }

                }


            }
        }
    }

    .product-status {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        padding: 5px 12px;
        border-radius: 8px;
        text-transform: uppercase;
        text-align: center;
        color: #DADADA;

        &.undetected {
            background-color: #28AD25;
        }

        &.recommend {
            background-color: #2D9CDB;
        }

        &.use-at-own-risk {
            background-color: #9400D3;
        }

        &.on-update {
            background-color: #1E1E1E;
        }

    }




}