footer {
    margin-top: 64px;
    padding: 64px 0;
    border-top: 1px solid #414141;

    @media (max-width: 992px) {
        text-align: center;
    }

    .slogan {
        color: rgba(255, 255, 255, .7);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 29px;
    }

    .navbar-footer {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;

        @media (max-width: 992px) {
            display: block;
        }

        li {
            margin-right: 20px;

            @media (max-width: 992px) {
                margin-top: 10px;
                margin-right: 0;
            }


            &:last-child {
                margin-right: 0;
            }

            a {
                color: rgba(255, 255, 255, .7);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-left: 10px;
                padding-right: 10px;
                text-decoration: none;
                text-transform: uppercase;
                transition: color .4s ease;

                @media (max-width: 992px) {
                    display: block;
                }


                &:hover {
                    color: #FF9700;
                }

            }
        }
    }

    .copyright-box {
        margin-top: 58px;

        .buyhack-copy {
            color: rgba(255, 255, 255, .7);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .artdelight-copy {

            @media (max-width: 992px) {
                margin-top: 20px;
            }


            a {
                display: flex;
                align-items: center;
                text-decoration: none;

                p {
                    color: rgba(255, 255, 255, .7);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-bottom: 0;
                    margin: 0;
                    padding-right: 10px;

                    text-align: end;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}