.slider-box {
    margin-top: 64px;
    padding: 0;

    .slider-container {
        width: 100%;
        //height: 410px;
        overflow: hidden;
        border-radius: 23px;

        .slider {
            position: relative;

            .slick-dots {
                //height: 62px;
                list-style: none;
                text-align: center;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                border: none;

                li {
                    width: 25px;
                    height: 50px;
                    margin: 0;
                    padding-top: 30px;

                    &.slick-active {
                        button {
                            border: none;
                            background-color: #F09819;
                        }
                    }

                    button {
                        background-color: #414141;
                        border: none;
                        border-radius: 50%;
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                        text-decoration: none;

                        &:before {
                            content: "";
                        }
                    }

                }
            }


            img {
                width: auto;
                border: 0;
                border-radius: 23px;
                min-width: 100%;
                max-width: 100%;
                max-height: 450px;

            }

            .caption {
                width: 60%;
                height: calc(100% - 8px);
                position: absolute;
                color: #fff;
                top: 0;
                bottom: 0;
                right: 0;
                padding: 40px;
                clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%);
                border-top-right-radius: 23px;
                border-bottom-right-radius: 23px;

                background-color: rgba(0,0,0, .7);

                @media (max-width: 992px) {
                    width: 70%;
                    padding: 20px 40px;
                }

                @media (max-width: 530px) {
                    width: 90%;
                    padding: 10px 20px;
                }


                .text {
                    height: 100%;
                    //top:50%;
                    position: relative;
                    display: grid;
                    align-items: center;
                    justify-content: end;

                    h2 {
                        color: #FFF;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                        margin-bottom: 10px;

                        @media (max-width: 530px) {
                            margin-bottom: 5px;
                            font-size: 18px;
                        }


                    }

                    p {
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin-bottom: 20px;
                        text-transform: uppercase;

                        @media (max-width: 530px) {
                            font-size: 16px;
                            margin-bottom: 10px;
                        }

                        span {
                            color: #F09819;
                        }

                    }

                    .btn {
                        max-width: 200px;
                        color: #161621;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        padding: 10px 60px;
                        border: 0;
                        outline: none;
                        border-radius: 5px;
                        background: #F09819;
                        transition: background-color .4s ease;

                        @media (max-width: 530px) {
                            font-size: 14px;
                            padding: 0px 30px;
                        }


                        &:hover {
                            background: #FF9700;
                        }
                    }
                }


            }
        }
    }




}