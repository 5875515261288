html, body {
    padding: 0;
    margin: 0;

}

body {
    font-family: 'Source Sans Pro', sans-serif;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding: 0;
    margin: 0;
    background: #161621;

}

main {
    margin-top: 60px;

}

@media (min-width: 1600px) {
    .container-fluid {
        padding-right: calc(var(--bs-gutter-x) * 5.5);
        padding-left: calc(var(--bs-gutter-x) * 5.5);
    }
}


a:focus,
a:active,
button:focus,
button:active {
    outline: none !important;
    box-shadow: none !important;
}


.notification {
    padding: 10px 10px;
    text-align: center;


    &__text {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        a {
            background: #FF9700;
            padding: 5px 15px;
            border-radius: 8px;
            text-decoration: none;
            margin-left: 10px;
            color: #ffffff;

        }
    }
}

