.categories {
    position: relative;

    .title-box {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        position: relative;

        .title {
            h1 {
                color: #FFF;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                text-transform: uppercase;

            }

        }
        .line-between {
            position: absolute;
            height: 1px;
            background-color: red;
            right: 0;
            left: 50%;

        }

    }


    .card {
        padding: 0;
        margin-bottom: 20px;
        border-radius: 23px;
        background: #1D1D2A;

        &:hover {
            .image-box {
                img {
                    transform: scale(1.2);

                }

            }
        }


        &-header {
            padding: 0;

            .image-box {
                height: 207px;
                overflow: hidden;
                border-top-left-radius: 23px;
                border-top-right-radius: 23px;

                @media (max-width: 992px) {
                    height: auto;
                }

                img {
                    border-top-left-radius: 23px;
                    border-top-right-radius: 23px;
                    width: 100%;
                    height: auto;
                    transform: scale(1);
                    transition: all .4s ease;

                }
            }

        }

        &-body {
            //padding: 0;

            &__category-title {
                text-align: left;

                h2 {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 10px;
                }
            }

            &__price {
                text-align: right;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                span {
                    color: #F09819;

                }
            }

            &__products {
                position: relative;
                //display: flex;
                align-items: center;
                justify-content: start;



                .line-between {
                    position: absolute;
                    bottom: 50%;
                    left: 0;
                    height: 2px;
                    background-color: #414141;
                    //margin-left: 10px;
                    //width: 100%;
                }

                h3 {
                    color: #D6D6D6;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    position: relative;
                    z-index: 1;



                }


            }

            &__status {
                //display: flex;
                align-items: center;
                justify-content: end;

                color: #368D4E;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;

                text-transform: uppercase;
            }


            .btn {
                width: 100%;
                border: none;
                margin-top: 10px;

                &.btn-primary {
                    color: #D6D6D6;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    border-radius: 5px;
                    background: #262638;
                    transition: background-color .4s ease;

                    &:hover {
                        background: #FF9700;
                    }

                }
            }
        }

    }
}