.pages {
    margin-top: 44px;
    position: relative;

    
    .title-box {
        position: relative;
        margin-bottom: 34px;

        h1 {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
        }

    }

    .content-body {
        color: rgba(255, 255, 255, .7);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;

        padding: 20px;
        border-radius: 24px;
        background: #262638;

        p {
            margin-bottom: 10px;
        }

        a {
            color: rgba(240, 152, 25, .7);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            //margin: 0;

            li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }



}